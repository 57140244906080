<template>
    <div class="flex justify-center items-center h-full">
        <div class="relative flex flex-col bg-white p-10 sm:p-12 shadow-md rounded w-auto  sm:w-104">
            <img src="@/assets/images/logo_color.png" class="self-center mb-4" width="200px" alt="">
            <form class="flex flex-col border-t border-gray-300 pt-6">
                <input
                    v-model="email"
                    class="border border-gray-400 rounded py-2 px-3 mb-4 placeholder-gray-400 outline-none focus:border-indigo-300 transition"
                    type="text"
                    placeholder="E-mail"
                    required
                >
                <input
                    v-model="password"
                    class="border border-gray-400 rounded py-2 px-3 mb-2 placeholder-gray-400 outline-none focus:border-indigo-300 transition"
                    type="password"
                    :placeholder="$t('login_page.password')"
                    required
                    autocomplete="on"
                    @keyup.enter="loginAttempt"
                >
                <router-link to="password_reset" class="self-end mb-6 text-sm text-indigo-400 hover:text-indigo-500 transition">
                    {{ $t('login_page.forgot_you_password') }}
                </router-link>
                <button
                    v-wait:hidden="'logging'"
                    class="bg-indigo-500 text-white self-end py-2 px-6 rounded shadow hover:bg-indigo-400 transition outline-none focus:outline-none active:bg-blue-700"
                    @click.prevent="loginAttempt"
                >
                    {{ $t('login_page.sign_in') }}
                </button>
                <button
                    v-wait:visible="'logging'"
                    disabled
                    class="relative bg-indigo-500 text-white self-end py-2 px-6 rounded shadow hover:bg-indigo-400 transition outline-none focus:outline-none active:bg-blue-700"
                >
                    <pulse-loader :loading="true" color="#b5c7ff" size="10px" />
                </button>
            </form>
            <p
                class="relative sm:absolute bottom-0 mt-5 sm:mt-0 sm:mb-3 self-center text-sm text-red-500 transition"
                :class="showErrorMessage || 'hidden'"
            >
                {{ $t('login_page.incorrect_email_or_password_please_try_again') }}
            </p>
        </div>
    </div>
</template>
<script>
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';

export default {
    components: {
        PulseLoader,
    },

    data() {
        return {
            email:            '',
            password:         '',
            showErrorMessage: false,
        };
    },

    methods: {
        loginAttempt() {
            if (!this.firstValidation()) return false;
            // axios.get(process.env.VUE_APP_CORE_URL + 'airlock/csrf-cookie').then(response => {
            this.login();
            // });
        },

        login() {
            this.$wait.start('logging');

            axios.post(`${process.env.VUE_APP_CORE_API_URL}/login`, {
            // axios.post(process.env.VUE_APP_CORE_URL + 'login', {
                email:    this.email,
                password: this.password,
            })
                .then(response => this.responseSuccess(response))
                .catch(() => this.responseError());
        },

        firstValidation() {
            this.showErrorMessage = false;

            if (!this.email || !this.password) {
                this.showErrorMessage = true;
                return false;
            }

            return true;
        },

        responseSuccess(response) {
            this.showErrorMessage = false;
            const apiToken = response.data.api_token;
            const userUuid = response.data.uuid;

            Cookies.set('api_token', apiToken, { expires: 365 });
            Cookies.set('user_uuid', userUuid, { expires: 365 });
            Cookies.set('user_email', this.email, { expires: 365 });
            if(response.data.master_cookie) {
                Cookies.set('master_cookie', true, { expires: 365 });
            }

            window.location.href = '/';
            return;
            this.$store.commit('storeUserUuid', userUuid);

            axios.defaults.headers.common.Authorization = `Bearer ${apiToken}`;
            this.$wait.end('logging');
            window.location.href = '/';
            // this.$router.push('/');
        },

        responseError() {
            this.password = '';
            this.showErrorMessage = true;
            this.$wait.end('logging');
        },
    },
};
</script>
